<template>
  <div>
    <myheader></myheader>
    <div class="banimg">
      <!-- <img :src="view.attachment" alt=""> -->
      <img src="http://yzhs-1253640091.cos.ap-shanghai.myqcloud.com/5f944fa2cbc245c4398980473ce207a8" alt="">
    </div>
    <div class="mainvideo">
      <div class="input_video">
        <!-- <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        ></video-player> -->
        <video :src="view.video" controls="controls" height="600px"  width="1200px">
        your browser does not support the video tag
        </video>
      </div>
    </div>
    <div class="trivaltext">
      <h3>{{view.title}}</h3>
     <div  v-html="view.content">

     </div>
    </div>
    <div class="head flex-between">
      <div class="content">
        <div class="tou">
           <img :src="view.avatar">
        </div>
        <div class="c2 flex-between">
          <p>
            <span style="color: #FF7C00;">{{view.account}}</span>
            <span style="color: #ccc;">|</span>
            <img src="../../assets/other/vip.png" alt />
            <span>{{view.create_time}}</span>
          </p>
          <p>
            <img src="../../assets/shot/read.png" alt />
            <span>{{view.look_number}}人阅读</span>
            <img src="../../assets/other/collection.png" alt />
            <span>{{view.collectionCount}}人收藏</span>
          </p>
          <div class="pic">
            <div @click="sc(view.is_collection)">
								<img src="../../assets/2_p.png"  v-if="view.is_collection==1">
								<img src="../../assets/2.png"  v-if="view.is_collection==0">
								<p>收藏</p>
							</div>
            <div>
              <img src="../../assets/shot/fx2.png" alt />
              <p>分享</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vbottom">
      <h3>活动集锦</h3>
      <div class="activelist">
        <div class="item"  v-for="(item,i) in activelist" :key="i" @click="toactive(item.id)" >
          <div class="img">
           <img :src="item.attachment" alt="">
          </div>
          <div class="text">
            <p>{{item.title}}</p>
            <p>
              <img src="../../assets/other/bgcity.png" alt />
              <span>Phillip Arnold</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <myfooter></myfooter>
    <myright></myright>
  </div>
</template>

<script>
import myheader from "@/components/header.vue";
import myfooter from "@/components/footer.vue";
import myright from "@/components/right.vue";
import {takeViewAPI,collectionTakeDelAPI } from "@/api/trivialShot"
import {takeCollectionList} from "@/api/active"
export default {
  components: {
    myheader,
    myfooter,
    myright
  },
  data() {
    return {
     
      view:{},
		form2:{
			pageSize:6,
			page:1,
			pid:1
		},
		total2:1,
		activelist:[]

    };
  },
  created() {
     let id=this.$route.query.id
     this.id=id
	  this.getview(id)
	  this.getactivelist()
  },
  methods: {
    sc(n){
		  var data={
			  tid:this.id,
			  status:n==1 ? 0 : 1,
			  type:2
		  }
		  collectionTakeDelAPI(data).then(res=>{
			  this.$message.success(n==1 ? "取消收藏" : "收藏"+"成功")
			   this.view.is_collection=n==1 ? 0 : 1
         if (n==1) {
           this.view.collectionCount--
         } else {
           this.view.collectionCount++
         }
		  })	 
		 },
     toactive(id){
      this.$router.push({ path: '/imginfo',query:{id} })
      this.getview(id)
    
    },
	getview(id){
		  takeViewAPI({id}).then(res=>{
			  this.view=res.View
		  })
	},
	getactivelist(){

		takeCollectionList(this.form2).then(res=>{
			this.activelist=res.cateList
			this.total2=res.rowsCount
		})
	},
	handleCurrentChange2: function(currentPage){
      this.form2.page=currentPage
      this.getactivelist()
    },
  }
};
</script>

<style lang="scss" scoped="scoped">
.banimg {
  img {
    width: 100%;
  }
  height: 380px;
  overflow: hidden;
  min-width: 1200px;
}
.mainvideo {
  width: 1200px;
  height: 360px;
  // background-color: #f9f9f9;
  margin: 0 auto;
  position: relative;
  .input_video {
    position: absolute;
    top: -260px;
    width: 1200px;
    height: 600px;
    margin: 0 auto;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
  }
}
.trivaltext {
  width: 1200px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 300px;
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
    color: #333333;
    line-height: 50px;
  }
  p {
    font-size: 14px;
    color: #666666;
    line-height: 24px;
  }
}
.head {
  width: 1200px;
  margin: 20px auto;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 30px;
  .content {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tou {
      margin-right: 20px;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
    .c2 {
      flex: 1;
      > p {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-right: 20px;
          color: #333;
        }
        img {
          margin-right: 10px;
        }
      }
    }
  }
  .pic {
    display: flex;
    width: 130px;
    justify-content: space-around;
    img {
      width: 35px;
      height: 35px;
    }
    p {
      font-size: 12px;
      text-align: center;
    }
  }
}

.vbottom {
  width: 1200px;
  margin: 0 auto;

  h3 {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    line-height: 45px;
  }
  .activelist {
    display: flex;
    .item {
      width: 280px;
      margin-top: 20px;
      margin-right: 20px;
      .img {
        width: 280px;
        height: 160px;
        overflow: hidden;

        img {
          width: 280px;
          min-height: 160px;
          border-radius: 5px 5px 0 0;
        }
      }
      .text {
        background: #f5f5f5;
        padding: 5px 10px;
      }
      p {
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #333333;
        line-height: 24px;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
        span {
          color: #ff7c00;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
